import Context from './Context';
import LayerToggle from '../components/LayerToggle';
import MainMenu from './MainMenu';

const Header = ({ t, site, onShowMenu }) => {
  const home = site === 'cvd' ? '/search?best=true' : '/';
  return (
    <nav className="top-nav">
      <h1>
        <a href={home}>
          <span>{t('site_name')}</span>
        </a>
      </h1>
      <LayerToggle menu="main" onToggle={onShowMenu}>
        <span className="sr-only">{t('toggle_navigation')}</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </LayerToggle>
      <MainMenu toggleLayer={onShowMenu} />
    </nav>
  );
};

export default Context(Header);
