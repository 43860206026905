const MultiSortSelectComponent = ({ sort, onSort, options }) => {
  const sort_parts = sort.split(',');
  const is_disabled = (index, key) => {
    if (key === '') return false;
    switch (index) {
      case 0:
        return key === sort_parts[1] || key === sort_parts[2];
      case 1:
        return key === sort_parts[0] || key === sort_parts[2];
      case 2:
        return key === sort_parts[0] || key === sort_parts[1];
      default:
        return false;
    }
  };
  const should_be_visible = (index) => {
    switch (index) {
      case 0:
        return true;
      case 1:
        return sort_parts[0] !== 'relevance';
      case 2:
        return sort_parts[0] !== 'relevance' && sort_parts[1] !== 'relevance';
      default:
        return false;
    }
  };
  return [0, 1, 2].map((index) => {
    return (
      should_be_visible(index) && (
        <select
          key={index}
          className="sort-order-control"
          onChange={(select) => onSort(select.target.value, index)}
          value={sort_parts[index] || options[0].key}
        >
          {options.map((opt) => {
            return (
              <option
                key={opt.key}
                disabled={is_disabled(index, opt.key)}
                value={opt.key}
              >
                {opt.value}
              </option>
            );
          })}
        </select>
      )
    );
  });
};

const MultiSort = ({ t, sort, onSort }) => {
  const onMultiSort = (key, index) => {
    let sort_items = sort.split(',');
    sort_items.splice(index, 1, key);
    // if relevance is selected we have to clear out
    // any other sort terms past it's index
    if (sort_items[0] === 'relevance') {
      sort_items = ['relevance', null, null];
    } else if (sort_items[1] === 'relevance') {
      sort_items = [sort_items[0], sort_items[1], null];
    }
    onSort(sort_items.join());
  };
  const SORT_OPTIONS = [
    { key: '', value: t('search_page.sort_order.sorted_by') },
    { key: 'best', value: t('search_page.sort_order.best') },
    { key: 'relevance', value: t('search_page.sort_order.relevance') },
    { key: 'most_recent', value: t('search_page.sort_order.most_recent') },
    {
      key: 'highest_quality',
      value: t('search_page.sort_order.highest_quality'),
    },
  ];
  return (
    <div className="sort-order">
      <span class="sort-order-label">&nbsp;</span>
      <MultiSortSelectComponent
        options={SORT_OPTIONS}
        sort={sort}
        onSort={onMultiSort}
      />
    </div>
  );
};

export default MultiSort;
