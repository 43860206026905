import SearchBar from '../components/SearchBar';
import GuidedQuestions from '../components/GuidedQuestions';
import Context from '../components/Context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchTipsAdvanced from '../components/SearchTipsAdvanced';

const Home = ({ t, site, setPage, toggleLayer }) => {
  let navigate = useNavigate();

  useEffect(() => {
    setPage('home');
    if (site === 'cvd') {
      navigate('/search');
    }
  });

  const search = (query, best) => {
    navigate({
      pathname: '/search',
      search: `?q=${query}&best=${best}`,
    });
  };

  const toggleAdvanced = (e) => {
    e.preventDefault();
    navigate('/search');
  };

  return (
    <div id="page-content">
      <div className="home-page">
        <div className="home-page-header">
          <div
            dangerouslySetInnerHTML={{ __html: t('home_page.beta') }}
            className="home-page-beta"
          />
          <h1>{t('site_name')}</h1>
          <p className="intro">{t('home_page.intro')}</p>
          <p className="intro2">{t('home_page.intro2')}</p>
          <SearchBar onSearch={search} background={true} />
          <SearchTipsAdvanced
            onShowMenu={toggleLayer}
            onAdvanced={toggleAdvanced}
          />
        </div>
        <GuidedQuestions />
      </div>
    </div>
  );
};

export default Context(Home);
